var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "vendors-status"
  }, [_vm.scopeQs ? _c('div', [_c('SetupVendorModal', {
    ref: "vendorRulesModal",
    on: {
      "on-form-submit-success": _vm.handleModalFormSubmit
    }
  }), _c('Row', {
    attrs: {
      "type": "flex",
      "gutter": 24
    }
  }, [_c('Col', {
    staticClass: "small-widget-col",
    attrs: {
      "xs": 24,
      "sm": 24,
      "md": 24
    }
  }, [_c('Tabs', {
    attrs: {
      "type": "card",
      "animated": false,
      "data-tests": "vendors-management-table-tabs"
    },
    on: {
      "on-click": _vm.handleTabChange
    },
    model: {
      value: _vm.activeTabName,
      callback: function callback($$v) {
        _vm.activeTabName = $$v;
      },
      expression: "activeTabName"
    }
  }, _vm._l(Object.values(_vm.tabs), function (tab) {
    return _c('TabPane', {
      key: tab.name,
      staticClass: "tab-panel",
      attrs: {
        "label": "".concat(_vm.counts[tab.name], " ").concat(tab.label),
        "name": tab.name
      }
    }, [_c('FilterTable', {
      key: _vm.tableKey,
      attrs: {
        "namespace": "vendor",
        "trigger": _vm.refreshTable,
        "export-csv": _vm.vendorExportCsv,
        "fetch-items": _vm.vendorsGetVendors,
        "fetch-items-params": _vm.params(tab.name),
        "columns": _vm.generateCols,
        "search-filters": ['isInAdstxt', 'isInTcf', 'vendorSurveillanceStatus', 'search', 'isInGatp', 'show-category-exemption', 'isExempted'],
        "data-tests": "vendors-management-table"
      },
      on: {
        "on-items-fetched": function onItemsFetched(res) {
          return _vm.onVendorsFetched(res, tab.name);
        }
      }
    })], 1);
  }), 1)], 1)], 1), _c('br'), _c('WidgetTile', {
    attrs: {
      "api": "wvendors/dailyWatchlistedVendorsPercentageRequests",
      "unit": "%",
      "is-actionable": true
    }
  })], 1) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }